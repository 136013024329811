.bottom_container {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 4.5rem;
  /* top: calc(100vh - 4rem); */
  background-color: #d1f1e2;
  text-align: center;
}

.copyrighttext {
  display: block;
  color: #069caa;
  font-size: 0.8rem;
  font-family: "Montserrat", sans-serif;
  margin: 0;
}

.footer_icons {
  display: inline;
}

.instagram_icon:hover {
  color: #bc2a8d;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}
.youtube_icon:hover {
  color: red;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}
.twitter_icon:hover {
  color: blue;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.mail_icon:hover {
  color: green;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.linkedin_icon:hover {
  color: #0077b5;
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.github_icon:hover {
  color: rgb(44, 44, 44);
  -webkit-transition: color 500ms linear;
  -ms-transition: color 500ms linear;
  transition: color 500ms linear;
}

.nav-links a {
  border: 1px solid transparent;
  color: #007580;
  text-decoration: none;
  padding: 0.5rem;
  color: #11999e;
  font-family: "Montserrat", sans-serif;
  margin: 10px 10px;
  text-decoration: none;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  /* background: #dbf6e9; */
  /* border-color: #292929; */
  /* border-radius: 10%; */
  color: #1ad5e6;
  /* color: #eaf6f6; */
}

.social_icons{
  margin: 1rem .5rem .3rem;
  list-style: none outside;
  padding: 0; 
}
.social_icons li{
    display: inline-block;
    margin: 0 15px;
    padding: 0;
}
.social_icon{
    /* display: inline; */
    /* margin: 1rem .5rem; */
    height: 5rem;
    color: #013c8a;    
}
