.button {
    font: inherit;
    padding: 0.5rem 1.5rem;
    border: 1px solid #069caa;
    border-radius: 4px;
    background: #069caa;
    color: white;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    display: inline-block;
  }

  .button:focus {
    outline: none;
  }
  
  .button:hover,
  .button:active {
    background: #1bcddd;
    border-color: #069caa;
  }

  .button--isCompact{
    padding: 0.1rem 0.1rem;
    margin: auto;
    width: 2rem;
    height: 2rem;
    border-radius: 0;
    text-align: center;
  }
  .button--borderTopLeftRoundCorner{
    border-top-left-radius: 4px;
  }

  .button--borderTopRightRoundCorner{
    border-top-right-radius: 4px;
  }
  
  .button--inverse {
    background: transparent;
    color: #069caa;
  }
  
  .button--inverse:hover,
  .button--inverse:active {
    color: white;
    background: #1bcddd;
  }
  
  .button--danger {
    background: #e66e6e;
    border-color: #e66e6e;
  }
  
  .button--danger:hover,
  .button--danger:active {
    background: #f34343;
    border-color: #f34343;
  }
  
  .button:disabled,
  .button:hover:disabled,
  .button:active:disabled {
    background: #ccc;
    color: #979797;
    border-color: #ccc;
    cursor: not-allowed;
  }
  
  .button--small {
    font-size: 0.8rem;
  }
  
  .button--big {
    font-size: 1.5rem;
  }