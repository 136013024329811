.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.85);
    z-index: 10;

    opacity: 0;
    animation: fadeInAnimation ease 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  