.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 0.5rem;
}

.nav-links a {
  border: 1px solid transparent;
  color: #007580;
  text-decoration: none;
  padding: 0.5rem;
  color: #11999e;
  font-family: "Montserrat", sans-serif;
  margin: 10px 10px;
  text-decoration: none;

  opacity: 0;
  animation: fadeInAnimation ease 500ms;
  animation-delay: .3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  /* background: #dbf6e9; */
  /* border-color: #292929; */
  /* border-radius: 10%; */
  color: #1ad5e6;
  /* color: #eaf6f6; */
}

.nav-links button {
  cursor: pointer;
  border: 1px solid #292929;
  color: #292929;
  background: transparent;
  padding: 0.5rem;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  background: #292929;
  color: white;
}

@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: #007580;
    text-decoration: none;
  }

  .nav-links button {
    border: 1px solid white;
    color: white;
    background: transparent;
  }

  .nav-links button:hover,
  .nav-links button:active {
    background: #f8df00;
    color: #292929;
  }
}
