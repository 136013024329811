@import url('https://fonts.googleapis.com/css2?family=New+Tegomin&display=swap');

body {
    font-family: "Montserrat", sans-serif;
    margin: 0 0 5rem 0;
    background-color: #dbf6e9;
    text-align: center;
    color: #40514e;
  }
  
  p{
    line-height: 1.5;
  }
  
  /* a{
    
  }
  a:hover{
    
  } */

  
* {
    box-sizing: border-box;
  }
  
 