.place-item {
  margin: 1rem .5rem;
  width: 45%;
  height: 40%;
  display: inline-block;
  /* opacity: 0; */
  /* transition: transform 0.2s; */

  /* animation: fadeInAnimation ease 1s; */
  /* animation-iteration-count: 1; */
  /* animation-fill-mode: forwards; */
}
/* .place-item:hover {
  transform: scale(1.07);
} */

/* this animation is applied by code in jsx file */
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
    /* transform: translateX(-15px); */
    /* transform: scale(0); */
  }
  100% {
    opacity: 1;
    /* transform: translateX(0); */
    /* transform: scale(1); */
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.07);
  }
}

.place-item__content {
  padding: 0;
}

.place-item__info {
  padding: 0 1rem 1rem 1rem;
  text-align: left;
  line-height: 1.5;
  height: 3rem;
}

.place-item__tab {
  padding: 0 0.1rem 0.1rem 0.1rem;
  text-align: left;
  line-height: 1.5;
  height: 6rem;
  font-size: 0.9rem;
}
.place-item__tab h2 {
  font-size: 1rem;
}
.place-item__modal-extra span {
  color: blue;
  width: 7rem;
  /* margin-right: 1rem; */
  display: inline-block;
}
.place-item__tab p {
  font-size: 0.9rem;
}
.place-item__tab a:hover {
  color: blue;
}
.place-item__tab a:active {
  color: purple;
}

.place-item__image {
  width: 100%;
  height: 8rem;
  margin-right: 1.5rem;
  cursor: pointer;
}

.place-item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-item__modal-image {
  width: 100%;
  height: 15rem;
  margin-right: 1.5rem;
  cursor: pointer;
}
.place-item__modal-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.place-item__info h2,
.place-item__info h3,
.place-item__info p {
  font-size: 110%;
  margin: 0.5rem 0 0 0;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.place-item__info h2 {
  cursor: pointer;
}
.place-item__info p {
  font-size: 90%;
}

.place-item__tags {
  color: rgb(165, 165, 165);
  font-size: 80%;
  text-align: left;
  margin: 0 0.5rem 0.5rem;
  padding: 0 0.5rem;

  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* .place-item__actions {
    padding: 1rem;
    text-align: center;
    border-top: 1px solid #ccc;
  } */
.place-item__actions {
  text-align: center;
  padding: 0;
  margin: 0;
}

.place-item__modal-content {
  padding: 0;
}

.place-item__modal-actions {
  text-align: right;
}
.place-item__modal-description {
  height: 5rem;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;
  text-align: left;
  overflow: scroll;
  overflow-x: hidden;
}

.place-item__modal-extra, .place-item__modal-links {
  height: 5rem;
  padding: 0;
  margin: 0;
  margin-left: 0.5rem;
  text-align: left;
  overflow: scroll;
  white-space: nowrap;
}


.place-item__modal-links span{
  color: blue;
  width: 5rem;
  /* margin-right: 1rem; */
  display: inline-block;
}

/* .place-item__actions button,
  .place-item__actions a {
    margin: 0.5rem;
  }
   */

@media (min-width: 768px) {
  .place-item {
    margin: 1rem 1rem;
    width: 30%;
  }
  .place-item__image {
    height: 12.5rem;
  }
  .place-item__info {
    height: 6rem;
  }

  .place-item__info h2,
  .place-item__info h3,
  .place-item__info p {
    -webkit-line-clamp: 2;
  }
}
